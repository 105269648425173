.counter {
  font-family: 'Digital Numbers';
  font-size: 15em;
}

@media only screen and (max-width: 600px) {
  .counter {
    width: 70%;
    font-size: 9em;
    text-align: center;
  }
}
