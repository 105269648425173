@import url(https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Roboto+Mono&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.counter {
  font-family: 'Digital Numbers';
  font-size: 15em;
}

@media only screen and (max-width: 600px) {
  .counter {
    width: 70%;
    font-size: 9em;
    text-align: center;
  }
}

.progress-bar-row {
  margin-top: 3%;
  vertical-align: middle;
}

.progress {
  height: 100%;
  border-style: solid;
  border-width: 1px;
  border-color: black;
  background-color: #ffffff;
  box-shadow: 0px 4px 4 rgba(0, 0, 0, 0.25);
  border-radius: 0px;
}
.progress-bar {
  text-align: right;
  background-color: #000000;
}

@media only screen and (max-width: 600px) {
  .desktop {
    display: none;
  }
  .progress-bar-row {
    margin-top: 12.5%;
    margin-bottom: 12.5%;
  }
  .progress {
    height: 200%;
  }
}
@media only screen and (min-width: 600px) {
  .mobile {
    display: none;
  }
}

#study-btn-row {
  margin-top: 0%;
}

#study-btn-col {
  width: 35%;
}

#counter-row {
  margin-top: 6%;
}

#button-row {
  margin-top: 1.5%;
  margin-bottom: 3%;
}

#button-col,
#study-btn-col,
#counter-col {
  text-align: center;
}

.setting-btns {
  width: 15%;
  margin-right: 5%;
  margin-left: 5%;
}

.setting-btns:hover,
#study-btn:hover {
  cursor: pointer;
}

/* Timer Mobile */
@media only screen and (max-width: 600px) {
  #study-btn {
    width: 90%;
  }
  .setting-btns {
    margin-top: 1.5rem;
    width: 23%;
    margin-bottom: 1.5rem;
  }
  #study-btn-row {
    margin-top: 4%;
  }
}

.form-row {
  display: flex;
  align-items: center;
  margin-top: 7.5%;
}
/* Label */
.form-label {
  text-align: center;
}

/* Slider */
.form-range {
  -webkit-appearance: none;
  -moz-appearance: none;
       appearance: none;
  opacity: 0.7;
  display: flex;
  align-items: center;
}
.form-range:hover {
  opacity: 1;
}

.form-range::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  appearance: none;
  height: 1px;
  background-color: #000000;
}

.form-range::-moz-range-track {
  height: 1px;
  background-color: #000000;
}

.form-range::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 0.75rem;
  height: 0.75rem;
  background: #7c7c7c;
  border-radius: 1px;
  cursor: pointer;
}
.form-range::-moz-range-thumb {
  width: 0.75rem;
  height: 0.75rem;
  background: #7c7c7c;
  border-radius: 1px;
  cursor: pointer;
}

.form-range:focus::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  box-shadow: none;
  background-color: #000000;
}
.form-range:focus::-moz-range-thumb {
  box-shadow: none;
  background-color: #000000;
  margin: 0px;
}

/* Range Value */
.range-mins-col {
  padding: 0px;
}
.range-mins {
  vertical-align: middle;
}

.range-value,
.mins-text {
  margin: 0px;
}
.range-value {
  font-size: calc(10px + 0.75vmin);
  color: #ffffff;
  font-weight: bold;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACMAAAAkCAYAAAAD3IPhAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAFOSURBVHgB7ZixccIwFIZ/oEnpDeJMEEZwJog3iEbwBrCB6TJGskEuZSook8rZgDad8l78TMBInDFIz4W/u5/jhCw+S893kgE3CaWgvFAqiu2ZtYxh0JOcsqW8ySAp+jOXMZqbMudcXMpFGa5PKmMvunTmTjytCcKRyH+UpzoZ1NaJZ4Br1k8h4+Q+Gf4xdbQ39WMDZAvHzRvKq0OkDCSxn6Plcj16iwgiTiFeyzkOZ8pGzq5+LA6pFGR29WOVZ6VJMW3NyiP0yNoyKfS4n6Ceook0WCgyxYAYZXyMMj5GGR+jjA+W+abcYgCwzIbygAHAMu+UJwwE3tTw5iaD3l7mLzP6+KF8UZ5F7gY6bGby5ZNyhzAnya58tBtW0Fsm05Zp6ie2SAUPuYKMwQliLtcSHVhGEFnhDAzCHOq4LnP0xKA+k68vEKjw/0rE+Q7oF0hCvYPJQoTLAAAAAElFTkSuQmCC);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 5px;
}
.mins-text {
  margin-left: 6px;
}

/* Form */
.background-body {
  margin-top: calc(50px - 3vh);
  font-family: 'Roboto Mono', monospace;
}

.form-background {
  border-style: solid;
  border-color: #000000;
  border-width: 2px;
  text-align: center;
  box-shadow: -6px 12px 4px rgba(0, 0, 0, 0.25);
  margin-top: 2.5%;
  margin-bottom: 10%;
}

.form-header {
  font-weight: bold;
  text-shadow: -1px 2px 0 rgba(0, 0, 0, 0.25);
  margin-top: 2.5%;
  margin-bottom: 18%;
}

.info-form {
  margin-top: 15%;
  margin-bottom: 15%;
}

#submit-btn {
  width: 60%;
  font-size: calc(30px - 1vh);
  font-weight: bold;
  border-radius: 10px;
  margin-bottom: 16%;
}

#submit-btn:hover {
  cursor: pointer;
}

/* Form Background */
@media only screen and (max-width: 600px) {
  .background-body {
    text-align: center;
  }
  .form-background {
    margin-top: 0px;
    width: 92%;
    margin-left: 4%;
    text-align: center;
  }
  #submit-btn {
    width: 80%;
  }
}

/* Background */
.background {
  border-style: solid;
  border-width: 3px;
  border-color: #000000;
  height: 95%;
  margin-top: 2%;
}

.background-header {
  border-bottom: solid;
  border-color: #000000;
  border-width: 1.75px;
  font-family: 'Abril Fatface', cursive;
  text-align: left;
}

/* Side Text */
@media only screen and (max-width: 600px) {
  .background {
    width: 95%;
    margin-left: 2.5%;
  }
  .text-col-desktop {
    display: none;
  }
  .text-col-desktop {
    display: none;
  }
  .text-col-mobile {
    text-align: left;
  }
  .text-img-mobile {
    width: 100%;
  }
}

@media only screen and (min-width: 600px) {
  .text-col-desktop {
    padding: 0px;
    position: relative;
  }

  .text-img-desktop {
    position: absolute;
    bottom: 0;
  }
  .text-col-mobile {
    display: none;
  }
}

.App {
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  padding: 0px;
  font-family: 'Roboto Mono', monospace;
}

@font-face {
  font-family: 'Digital Numbers';
  src: url(/static/media/DS-DIGI.0ef0e224.TTF);
}

