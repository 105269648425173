@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Roboto+Mono&display=swap');

.App {
  height: min-content;
  padding: 0px;
  font-family: 'Roboto Mono', monospace;
}

@font-face {
  font-family: 'Digital Numbers';
  src: url('./fonts/DS-DIGI.TTF');
}
