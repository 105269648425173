.progress-bar-row {
  margin-top: 3%;
  vertical-align: middle;
}

.progress {
  height: 100%;
  border-style: solid;
  border-width: 1px;
  border-color: black;
  background-color: #ffffff;
  box-shadow: 0px 4px 4 rgba(0, 0, 0, 0.25);
  border-radius: 0px;
}
.progress-bar {
  text-align: right;
  background-color: #000000;
}

@media only screen and (max-width: 600px) {
  .desktop {
    display: none;
  }
  .progress-bar-row {
    margin-top: 12.5%;
    margin-bottom: 12.5%;
  }
  .progress {
    height: 200%;
  }
}
@media only screen and (min-width: 600px) {
  .mobile {
    display: none;
  }
}
