/* Background */
.background {
  border-style: solid;
  border-width: 3px;
  border-color: #000000;
  height: 95%;
  margin-top: 2%;
}

.background-header {
  border-bottom: solid;
  border-color: #000000;
  border-width: 1.75px;
  font-family: 'Abril Fatface', cursive;
  text-align: left;
}

/* Side Text */
@media only screen and (max-width: 600px) {
  .background {
    width: 95%;
    margin-left: 2.5%;
  }
  .text-col-desktop {
    display: none;
  }
  .text-col-desktop {
    display: none;
  }
  .text-col-mobile {
    text-align: left;
  }
  .text-img-mobile {
    width: 100%;
  }
}

@media only screen and (min-width: 600px) {
  .text-col-desktop {
    padding: 0px;
    position: relative;
  }

  .text-img-desktop {
    position: absolute;
    bottom: 0;
  }
  .text-col-mobile {
    display: none;
  }
}
