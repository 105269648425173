#study-btn-row {
  margin-top: 0%;
}

#study-btn-col {
  width: 35%;
}

#counter-row {
  margin-top: 6%;
}

#button-row {
  margin-top: 1.5%;
  margin-bottom: 3%;
}

#button-col,
#study-btn-col,
#counter-col {
  text-align: center;
}

.setting-btns {
  width: 15%;
  margin-right: 5%;
  margin-left: 5%;
}

.setting-btns:hover,
#study-btn:hover {
  cursor: pointer;
}

/* Timer Mobile */
@media only screen and (max-width: 600px) {
  #study-btn {
    width: 90%;
  }
  .setting-btns {
    margin-top: 1.5rem;
    width: 23%;
    margin-bottom: 1.5rem;
  }
  #study-btn-row {
    margin-top: 4%;
  }
}
